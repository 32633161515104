import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout/Layout';
import SEO from 'components/SEO/SEO';
import ZipGateContainer from 'components/ZipGateContainer/ZipGateContainer';
import Hero from 'components/Hero/Hero';
import { KitsTiles } from 'components/ProductKits';
import { BundleHowItWorksContainer } from 'components/HowItWorks';
import InfoBlock from 'components/InfoBlock';


const SelfInstallPage = ({ data, location }) => (
  <Layout location={location} type="standard">
    <SEO
      meta={data.selfInstallYaml.metaTags}
      title={data.selfInstallYaml.title}
    />
    <Hero data={data.selfInstallYaml.hero} />
    <Fragment>
      <BundleHowItWorksContainer data={data.selfInstallYaml.bundleHowItWorks} />
      <KitsTiles
        customizable={false}
        data={data.productBundles}
      />
      <InfoBlock data={data.selfInstallYaml.infoBlock} />
    </Fragment>
    <ZipGateContainer location={location} />
    <hr />
  </Layout>
);

export default SelfInstallPage;

export const pageQuery = graphql`
  query SelfInstallPageQuery {
    selfInstallYaml {
      id
      title
      metaTags {
        name
        content
      }
      hero {
        size
        title
        subtitle
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        heroImagePosition
        separator
      }
      bundleHowItWorks {
        title
        backgroundWhite
        items {
          image {
            publicURL
          }
          preTitle
          title
          description
        }
      }
      infoBlock {
        title
        infoCards {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 535, maxHeight: 405) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    productBundles: productBundlesYaml {
      bundles {
        productId
        kit
        title
        subtitle
        link {
          url
          text
        }
        description
        icon {
          childImageSharp {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1050, maxHeight: 780) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        images {
          childImageSharp {
            fluid(maxWidth: 1700, maxHeight: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        devices {
          title
          items
        }
        addons {
          title
          text
        }
        price
        requirements {
          text
          button
          planId
        }
      }
      blockData {
        title
        description
        disclaimer
      }
    }
  }
`;
